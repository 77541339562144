<div class="section" id="education">
    <div class="container cc-education">
        <div class="h4 text-center mb-4 title">Education</div>
            <div class="card">
                <div class="row">
                    <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
                        <div class="card-body cc-education-header">
                        <div class="h5">Bachelor Degree</div>
                        </div>    
                    </div>
                    <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
                        <div class="card-body">
                            <div class="h4">Bachelor of Computer Science</div>
                                <h4 class="category">Towson University</h4>
                                <ul>
                                    <li> Completed degree while working. </li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>                                               