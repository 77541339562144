<div class="section" id="about">
    <div class="container">
        <div class="card" data-aos="fade-up" data-aos-offset="10">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="card-body">
                        <div class="h4 mt-0 title">About Me</div>
                            <p align="justify">I love spending time building applications that people love. In my experience nothing is more rewarding that seeing your hard work pay off and get feedback from users on how much they like what you've created. When designing I always try to make sure my applications are both easy to use and powerful for users.</p>
                            <p align="justify">To learn more about me, keep exploring my site or feel free to reach out directly.</p>
                    </div>
                </div>    
                <div class="col-lg-6 col-md-12">       
                    <div class="card-body">
                        <div class="h4 mt-0 title">Basic Information</div>
                        <div class="row mt-4">
                            <div class="col-sm-4">
                                <strong class="text-uppercase">Email:</strong>
                            </div>
                            <div class="col-sm-8">cmccoy5695@gmail.com</div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-4">
                                <strong class="text-uppercase">Location:</strong>
                            </div>
                            <div class="col-sm-8">Maryland</div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-4">
                                <strong class="text-uppercase">Language:</strong>
                            </div>
                            <div class="col-sm-8">English</div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
</div>

