<div class="section" id="projects">
	<div class="container">
		<div class="h4 text-center mb-4 title">Projects</div>
		<div class="card" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<div class="card-body">
				<div>
					<div class="row">
						<div class="col-lg-6 ">
							<div class="h5 mt-0 title">Assignr</div>
							<div class="h6 mt-0 title">Complete University Website Toolkit</div>
							<p align="justify">A project I'm working on in my free time. Assignr is an attempt at
								building a better university online experience for students. When I, and many other
								students, were in college we found that Oracle's peoplesoft implementation for handling
								online interactions to be clunky, unresponsive and unpleasant. A solution to this needed
								to be made and so I started working on this app.</p>
						</div>
						<div class="col-lg-6">
							<ul>
								<li class="list">Technologies and Language</li>
								<ul>
									<div class="row">
										<div class="col-md-6">
											<li class="non_list">Angular 13</li>
											<li class="non_list">Postgres</li>
											<li class="non_list">Nginx</li>
										</div>
										<div class="col-md-6">
											<li class="non_list">Spring Boot</li>
											<li class="non_list">Java</li>
											<li class="non_list">Hibernate</li>
										</div>
									</div>
								</ul>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>