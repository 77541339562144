<div class="section" id="experience">
    <div class="container py-2 cc-experience">
        <div class="h4 text-center mb-4 title">Work Experience</div>
                <div class="row">
                    <ul class="timeline">
                        <li>
                            <div class="timeline-badge up bg-green">
                                <i class="fa fa-thumbs-up"></i>
                            </div>
                            <div class="timeline-panel">
                                <div class="row">
                                    <div class="col-lg-6 ">
                                        <div class="h5 mt-0 title">Federal Government</div>
                                        <div class="h6 mt-0 title">Software Engineer (Current)</div>
                                        <p align="justify">Full stack website development.</p>
                                    </div>    
                                    <div class="col-lg-6">       
                                        <ul>
                                            <li class="list">Core Functionality</li>
                                            <ul>
                                                <li class="non_list">Software Development</li>
                                                <li class="non_list">Application Development</li>
                                            </ul>
                                            <li class="list">Technologies and Language</li>
                                            <ul>
                                                <div class="row">
                                                    <div class="col-md-6">
														<li class="non_list">Angular 13</li>
														<li class="non_list">React JS</li>
                                                        <li class="non_list">AngularJS</li>
                                                        <li class="non_list">Typescript</li>
														<li class="non_list">Linux</li>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <li class="non_list">Spring Boot</li>
                                                        <li class="non_list">Java</li>
                                                        <li class="non_list">Hibernate</li>
                                                        <li class="non_list">Postgres</li>
                                                        <li class="non_list">MySQL</li>
                                                    </div>
                                                </div>   
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>   
                        <li>
                            <div class="timeline-badge down">
                                <i class="fa fa-users"></i>
                            </div>
                            <div class="timeline-panel">
                                <div class="row">
                                    <div class="col-lg-6 ">
                                        <div class="h5 mt-0 title">McCoy State Delegate Campaign</div>
                                        <div class="h6 mt-0 title">Sys Admin/Software Engineer (2018 - 2021)</div>
                                        <p align="justify">As both the System Administrator and Software Engineer of campaign's website. Talked to the customer and got feedback which allowed us to design a sleek and mobile friendly site.</p>
                                    </div>    
                                    <div class="col-lg-6">       
                                        <ul>
                                            <li class="list">Core Functionality</li>
                                            <ul>
                                                <li class="non_list">Web Development</li>
                                                <li class="non_list">System Security</li>
                                            </ul>
                                            <li class="list">Technologies and Language</li>
                                            <ul>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <li class="non_list">HTML</li>
                                                        <li class="non_list">CentOS 7</li>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <li class="non_list">Javascript</li>
                                                        <li class="non_list">CSS</li>
                                                    </div>
                                                </div>   
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
    </div>
</div>
        