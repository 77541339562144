<div class="profile-page">
    <div class="wrapper">
        <div class="page-header page-header-small" filter-color="orange">
            <ul class="circles">
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
				<li></li>
			</ul>
        <div class="container">
            <div class="content-center">
                <div class="profile-image">
                    <a href="#">
                        <img src="assets/images/profile.jpg" alt="Image" />
                    </a>
                </div>
                <div class="h2 title">Casey McCoy </div>
                <p class="category text-white">Full Stack Developer</p>
                <a class="btn btn-primary smooth-scroll mr-2" href="#contact" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Hire Me</a>
				<!-- <a class="btn btn-primary mr-2"href="resume here" target="_blank" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">View Resume</a> -->
            </div>
        </div>
        </div>
    </div>
</div>
